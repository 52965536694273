import requireAuth from "../context/requireAuth";
import axios from "../context/axiosConfig";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Home() {

    const [user, setUser] = useState({})
    console.log(user)
    useEffect(() => {
        axios.get("account")
            .then(res => setUser(res.data))
            .catch(err => console.log(err));
    }, [])
   
    useEffect(() => {
        document.title = "Innexo - Welcome";
    }, [])

    return(
        <div className="bodyContent">
            <div className="centered">
               <h1 className="title"> Hi {user?.firstname} {user?.lastname}, <br />
                welcome to your dashboard!
                </h1>
                <div className="homeGrid">
                    <Link to="/roomselect">
                        <div className="purpleBox">
                            <p className="linkText">Room & Plots</p>
                        </div>
                    </Link>
                    <Link to="/plantSelect">
                    <div className="purpleBox">
                        <p className="linkText">Plants</p>
                    </div>
                    </Link>
                    <Link to="/editAccount">
                    <div className="purpleBox">
                        <p className="linkText">Alter Account</p>
                    </div>
                    </Link>
                    <Link to="createNew">
                    <div className="purpleBox">
                        <p className="linkText">Create New</p>
                    </div>
                    </Link>
                    <Link to="maintenance">
                    <div className="purpleBox">
                        <p className="linkText">Maintenance</p>
                    </div>
                    </Link>
                    <Link to="product">
                    <div className="purpleBox">
                        <p className="linkText">Product</p>
                    </div>
                    </Link>
                </div>
                </div>
            </div>
    )
}

export default requireAuth(Home);