import { useMemo, useRef } from "react";
import requireAuth from "../context/requireAuth";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import { Button, Grid } from "@mui/material";
import axios from "../context/axiosConfig";

function DestroySignature() {

    const navigate = useNavigate();

    const signature1Ref = useRef();
    const signature2Ref = useRef();

    console.log({ signature1Ref, signature2Ref })
    /**
     * @type {{type: string, page: string, objects: string[]}}
     */
    const deleteObject = useMemo(() => {
        const deleteSession = window.sessionStorage.getItem("toDelete");

        console.log({ delete: "test", deleteSession })

        if (!deleteSession) {
            console.log("navigating to main")
            navigate("/main");
            return {}
        } else {
            try {
                return JSON.parse(deleteSession)
            } catch (error) {
                console.error(error)
                navigate("/main")
                return {}
            }
        }
    }, [navigate])

    const handleDestroyObjects = async (event) => {
        event.preventDefault()
        if (signature1Ref.current.isEmpty() || signature2Ref.current.isEmpty()) {
            alert("Please sign both fields!");
            return;
        }

        const signature1DataURL = signature1Ref.current.toDataURL();
        const signature2DataURL = signature2Ref.current.toDataURL();

        switch (deleteObject.type) {
            case "plant":
                await handlePlantDestroy(signature1DataURL, signature2DataURL);
                break;
            case "plot":
                await handlePlotDestroy(signature1DataURL, signature2DataURL);
                break;
            default:
                console.error(`deleteObjectType ${deleteObject.type} is not a valid type!`)
                alert("Something went wrong!")
        }
        window.sessionStorage.removeItem("toDelete")
        navigate(deleteObject.page);
    }

    const handlePlantDestroy = (signature1DataURL, signature2DataURL) => {
        return new Promise((resolve, reject) => {


            const body = {};
            console.log({ deleteObject })
            deleteObject.objects.forEach((plant, index) => {
                body[`uid${index + 1}`] = plant
            })
            body["signature1"] = signature1DataURL;
            body["signature2"] = signature2DataURL;
            body["size"] = deleteObject.objects.length;


            axios
                .post(`plant/deleteplants`, body)
                .then((res) => {
                    alert(`Plants were succesfully destroyed`);
                    resolve()
                })
                .catch((error) => {
                    console.error(error);
                    alert("Something went wrong! Try Again");
                    reject()
                });
        })
    }

    const handlePlotDestroy = (signature1DataURL, signature2DataURL) => {
        return new Promise((resolve, reject) => {
            const body = {signature1: signature1DataURL, signature2: signature2DataURL}
            axios
                .delete(`/plot/${deleteObject.objects[0]}`, {data: body})
                .then((res) => {
                    alert(res.data.message);
                    resolve()
                })
                .catch((error) => {
                    console.error(error);
                    alert(`Something went wrong! ${error.request.response}`);
                    reject()
                });
        })
    }

    if (!deleteObject.objects) {
        return (
            <div className="bodyContent">
                <h1>Something went wrong!</h1>
                <h3>
                    Please try again later. If this error persists, please contact
                </h3>
                <a target="_blank" rel="noreferrer" href="mailto:support@yookr.org">
                    yookr: support@yookr.org
                </a>
            </div>
        )
    }


    return (
        <div className="bodyContent">
            <h2 className="subheader">Destroy {deleteObject.type}</h2>
            <h4>
                Are you sure that you want to delete the following {deleteObject.type}{deleteObject.objects.length === 1 ? "" : "s"}: {deleteObject.objects.join(",")}
            </h4>
            <Grid container gap={2} justifyContent="space-evenly">
                <Grid item>
                    <h3>Signature 1 - <Button variant="contained" color="error" onClick={() => signature1Ref.current.clear()}>Clear</Button></h3>
                    <SignatureCanvas canvasProps={{ width: 500, height: 300, className: 'signatureCanvas' }} ref={signature1Ref} />
                </Grid>
                <Grid>
                    <h3>Signature 2 - <Button variant="contained" color="error" onClick={() => signature2Ref.current.clear()}>Clear</Button></h3>
                    <SignatureCanvas canvasProps={{ width: 500, height: 300, className: 'signatureCanvas' }} ref={signature2Ref} />

                </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ margin: "1em 0" }}>
                <form className="editForm" onSubmit={handleDestroyObjects}>
                    <Button sx={{ fontSize: "30px" }} type="submit" variant="contained" color="error">Destroy</Button>
                </form>
            </Grid>
        </div>
    )
}


export default requireAuth(DestroySignature);