import React from "react";
import { useState } from 'react';
import requireAuth from "../context/requireAuth";
import axios from "../context/axiosConfig";

function NewPlant() {
    const [formData, setFormData] = useState({
      variety: '',
      date: '',
      sex: 'female',
      stock: 'seed',
      ammount: 1
    });

    const now = new Date(Date.now()).toISOString().split('T')[0];

  
    function handleSubmit(event) {
      event.preventDefault();
      axios.post('/plant', formData)
        .then(response => {
          console.log(response.data);
          const plantsCreated = response.data.map(plant => plant.uid);
          if (plantsCreated.length > 2) {
            alert(`${plantsCreated.length} plants created with PlantID from ${plantsCreated[0]} till ${plantsCreated[plantsCreated.length - 1]}`);
          } else {
            alert(`Plant(s) created with PlantID: ${plantsCreated.join(', ')}`);
          }
          setFormData({
            variety: '',
            date: now,
            sex: 'female',
            stock: 'seed',
            amount: 1
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
    
  
    function handleChange(event) {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }   

    return (
        <div className="bodyContent">
            <h1 className="title">New Plant</h1>
            <div className="editFormDiv">
              <form className="editForm" onSubmit={handleSubmit}>
                <label htmlFor="variety">Variety</label>
                <input type="text" name="variety" id="variety" className="editInput" value={formData.variety} onChange={handleChange} />
                <br />
                <label htmlFor="date">Creation Date</label>
                <input type="date" name="date" id="date" className="editInput" value={now} onChange={handleChange} disabled/>
                <br />
                <label htmlFor="sex">Sex</label>
                <select name="sex" id="sex" className="editInput editSelect" value={formData.sex} onChange={handleChange}>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="other">Other</option>
                </select>
                <br />
                <label htmlFor="stock">Stock Type</label>
                <select name="stock" id="stock" className="editInput editSelect" value={formData.stock} onChange={handleChange}>
                  <option value="seed">Seed</option>
                  <option value="clone">Clone</option>
                  <option value="extern">Extern</option>
                </select>
                <br />
                <label htmlFor="amount">Amount to Create</label>
                <input type="number" min="1" max="500" name="amount" id="amount" className="editInput" value={formData.amount} onChange={handleChange} />
    
                <input type="submit" value="Create Plant(s)" className="maintenanceFormSubmit" />
              </form>
            </div>
          </div>
      );
    }

export default requireAuth(NewPlant);