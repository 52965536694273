import React from 'react';
import './App.css';
import { Route, HashRouter as Router, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/navbar.jsx';
import Home from './pages/home.jsx';
import Footer from './components/footer.jsx';
import Roomselect from './pages/roomselect.jsx';
import Login from './pages/login.jsx';
import Room from "./pages/room.jsx"
import Plot from './pages/plot';
import PlantOptions from './pages/plantOptions';
import PlantSelect from './pages/plantSelect';
import EditAccount from './pages/editAccount';
import CreateNew from './pages/createNew';
import NewRoom from './pages/newRoom';
import NewPlot from './pages/newPlot';
import NewPlant from './pages/newPlant';
import NewAccount from './pages/newAccount';
import PlacePlot from './pages/placePlot';
import EditRoom from './pages/editRoom';
import EditPlot from './pages/editPlot';
import ReplacePlot from './pages/replacePlot';
import PlacePlant from './pages/placePlant';
import NotFound from './errorHandler/notFound';
import Maintenance from './pages/maintenance.jsx';
import Product from './pages/product.jsx';
import NewProduct from './pages/newProduct.jsx';
import DestroyWithSignatures from './pages/destroyWithSignatures.jsx';
import Signatures from './pages/signatures.jsx';

function App() {
  return (
    <Router hashType="slash">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roomselect" element={<Roomselect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/room/:id" element={<Room />} />
        <Route path="/plot/:id" element={<Plot />} />
        <Route path="/plot/:id/plantOptions" element={<PlantOptions />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/plantSelect" element={<PlantSelect />} />
        <Route path="/editAccount" element={<EditAccount />} />
        <Route path="/createNew" element={<CreateNew />} />
        <Route path="/newRoom" element={<NewRoom />} />
        <Route path="/newPlot" element={<NewPlot />} />
        <Route path="/newPlant" element={<NewPlant />} />
        <Route path="/newAccount" element={<NewAccount />} />
        <Route path="/placePlot" element={<PlacePlot />} />
        <Route path="/editRoom/:id" element={<EditRoom />} />
        <Route path="/editPlot/:id" element={<EditPlot />} />
        <Route path="/replacePlot" element={<ReplacePlot />} />
        <Route path="/placePlant" element={<PlacePlant />} />
        <Route path="/product" element={<Product />}/>
        <Route path="/newProduct" element={<NewProduct />}/>
        <Route path="/destroy" element={<DestroyWithSignatures />} />
        <Route path="/signatures" element={<Signatures />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
