import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import { FaTimes } from "react-icons/fa";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { MaintenanceSelector } from "../components/MaintenanceSelector";

function Room() {
  const [plots, setPlots] = useState([]);
  const [room, setRoom] = useState({});
  const [plotsInRoom, setPlotsInRoom] = useState([]);
  const [plotsNotInRoom, setPlotsNotInRoom] = useState([]);
  const [NoPlotShowModal, setNoPlotShowModal] = useState(false);
  const [PlotShowModal, setPlotShowModal] = useState(false);
  const [selectedCol, setSelectedCol] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(false);
  const [selectedUid, setSelectedUid] = useState(false);
  const [selectedName, setSelectedName] = useState(false);
  const [inputs, setInputs] = useState({ uid: "" });
  const [grid, setGrid] = useState([]);
  const { id } = useParams();
  const [gridHtml, setGridHTML] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSublevel, setSelectedSublevel] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [desc, setDesc] = useState("");
  const [showRepeatModal, setShowRepeatModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedSev, setSelectedSev] = useState("");

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const [plotsRes, roomsRes] = await Promise.all([
        axios.get("plot"),
        axios.get(`room/roomid?roomid=${id}`),
      ]);
      setPlots(plotsRes.data);
      setRoom(roomsRes.data[0]);
      console.log('plots fetched')
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log('new plot fetch')
    fetchData();

  }, [id, NoPlotShowModal, PlotShowModal]);

  useEffect(() => {
    console.log(plots)
    setPlotsInRoom(plots.filter((plot) => plot.roomid == id));
    setPlotsNotInRoom(plots.filter((plot) => plot.roomid == null));
  }, [plots])

  useEffect(() => {
    console.log('reload grid')
    fillGrid(plotsInRoom);
  }, [plotsInRoom]);

  useEffect(() => {
    setGridHTML(renderGridHtml());
  }, [grid])


  const handleNoPlotDivClick = (event) => {
    const col = event.target.getAttribute("data-col");
    const row = event.target.getAttribute("data-row");
    const floor = event.target.getAttribute("data-floor");
    setSelectedCol(col);
    setSelectedRow(row);
    setSelectedFloor(floor);
    setNoPlotShowModal(!NoPlotShowModal);
  };

  const handlePlotDivClick = (event) => {
    const col = event.target.getAttribute("data-col");
    const row = event.target.getAttribute("data-row");
    const floor = event.target.getAttribute("data-floor");
    const uid = event.target.getAttribute("data-plotid");
    const name = event.target.getAttribute("data-plotname");
    setSelectedCol(col);
    setSelectedRow(row);
    setSelectedUid(uid);
    setSelectedName(name);
    setSelectedFloor(floor);
    setPlotShowModal(!PlotShowModal);
  };

  function fillGrid(plotInRoom) {

    const newGrid = Array.from({ length: room.size_z }, () =>
      Array.from({ length: room.size_y }, () =>
        Array.from({ length: room.size_x }, () => [])
      )
    );



    plotInRoom.forEach((plot) => {
      const row = parseInt(plot.loc_y) - 1;
      const col = parseInt(plot.loc_x) - 1;
      const floor = parseInt(plot.loc_z) - 1;

      if (newGrid[floor][row][col]) {
        // console.warn(`Duplicate plot at (${row},${col},${floor})`);
      }

      newGrid[floor][row][col] = plot;
    });

    setGrid(newGrid);
  }

  function renderGridHtml() {
    return grid.map((floor, floorIndex) => (
      <div key={floorIndex} className="GridFloor">
        <h3>Floor {floorIndex + 1}</h3>
        {floor.map((row, rowIndex) => (
          <div key={rowIndex} className="GridRow">
            {row.map((plot, colIndex) => {
              if (plot.plotid) {
                return (
                  <div
                    key={`location_[${colIndex},${rowIndex},${floorIndex}]`}
                    className="GridItem"
                    data-col={colIndex + 1}
                    data-row={rowIndex + 1}
                    data-floor={floorIndex + 1}
                    data-plotid={plot.plotid}
                    data-plotname={plot.plotno}
                    onClick={handlePlotDivClick}
                  >
                    Number:{plot.plotno} - ID:{plot.plotid}
                  </div>
                );
              } else {
                return (
                  <div
                    key={`location_[${colIndex},${rowIndex},${floorIndex}]`}
                    data-col={colIndex + 1}
                    data-row={rowIndex + 1}
                    data-floor={floorIndex + 1}
                    className="GridItem nonActive"
                    onClick={handleNoPlotDivClick}
                  >
                    No Plot
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>
    ));
  }

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    axios
      .delete(`plot/${selectedUid}`)
      .then((res) => {
        alert(res.data.message);
        setPlotShowModal(false);
        fetchData();
      })
      .catch((error) => {
        console.error(error);
        alert("Something went wrong! Try Again");
      });
  };

  const handlePlaceSubmit = async (e) => {
    e.preventDefault();
    try {
      const plotValues = {
        roomid: parseInt(id),
        loc_y: parseInt(selectedRow),
        loc_x: parseInt(selectedCol),
        loc_z: parseInt(selectedFloor),
      };

      await axios.patch(`plot/${inputs.uid}`, plotValues);
      setNoPlotShowModal(false);
      fetchData();
    } catch (err) {
      console.log(err);
      alert("Something went wrong! Try Again");
    }
  };


  const toggleShow = () => {
    setPlotShowModal(!PlotShowModal);
  }

  useEffect(() => {
    if (!PlotShowModal) {
      setSelectedCategory(null); // Reset selectedCategory when modal closes
      setSelectedSublevel(null); // Reset selectedSublevel when modal
    }
  }, [PlotShowModal]);

  //Write the code for all change handlers

  const handleRepeatAction = (repeat) => {
    if (repeat) {
      // Clear the UID input or any other fields that need to be reset
      setSelectedUid("");
      setSelectedCol(null);
      setSelectedRow(null);
      setIsEditable(true);

      // Close the "Repeat Action" modal
      setShowRepeatModal(false);
    } else {
      // Close the form or perform any other necessary action
      // Reset any other form-related state variables
      setIsEditable(false);
      setShowRepeatModal(false);
      setPlotShowModal(false);
    }
  };


  const toggleNoShow = () => {
    setNoPlotShowModal(!NoPlotShowModal);
  }

  const deleteRoom = (e) => {
    e.preventDefault();
    axios
      .delete(`/room/${room.roomid}`)
      .then((res) => {
        alert(res.data.message);
        setPlotShowModal(false);
        fetchData();
        goBack()
      })
      .catch((error) => {
        console.error(error);
        alert(`Something went wrong! ${error.request.response}`);
      });
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="bodyContent">
      <h2 className="subheader">Room {room.roomid}</h2>
      <br />
      <h3 style={{ marginBottom: "0", marginLeft: "20px" }}>Floors: {room.size_z} Rows: {room.size_y} Columns: {room.size_x}</h3>
      <br />
      <Link to={`/editRoom/${room.roomid}`}>
        <input type="button" value="Edit Room" className="purpleBtn" />
      </Link>
      <Link to={`/newPlot`}>
        <input type="button" value="New Plot" className="purpleBtn" />
      </Link>
      <form method="POST" onSubmit={deleteRoom} className="inlineDeleteBtn">
        <input
          type="submit"
          value="Delete Room"
          className="deleteBtn roomDeleteBtn"
        />
      </form>


      {/* Make grid dynamic with database values */}

      <div className="Grid">{gridHtml}</div>

      {PlotShowModal && (
        <MDBModal show={PlotShowModal} setShow={setPlotShowModal} tabIndex="-1">

          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  <div className="centered relativeCentered">
                    <button
                      className="close-btn"
                      onClick={toggleShow}
                    >
                      <FaTimes />
                    </button>
                    <h1 className="title">Info Plot: {selectedName}</h1>
                    <p>Row: {selectedRow} Column: {selectedCol} Floor: {selectedFloor} ID: {selectedUid}</p>
                  </div>
                  <div className="gotoLinkWrapper">
                    <Link key={selectedName} to={`/plot/${selectedUid}`} className="gotoLink">
                      <div className="maintenanceFormSubmit gotoBtn">
                        Go to {selectedName}
                      </div>
                    </Link>
                  </div>


                  <h1 className="title">Maintenance for whole plot</h1>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                <MaintenanceSelector repeatAction={handleRepeatAction} selectedUid={selectedUid} setSelectedUid={setSelectedUid} type={"plot"} />
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

      )}



      {NoPlotShowModal && (
        <MDBModal show={NoPlotShowModal} setShow={setNoPlotShowModal} tabIndex="-1">

          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  <button
                    className="close-btn"
                    onClick={toggleNoShow}
                  >
                    <FaTimes />
                  </button>
                  <h1 className="title">Place Plot</h1>
                  <div className="centered relativeCentered">
                    Row: {selectedRow} Column: {selectedCol} Floor: {selectedFloor}
                  </div>
                  <div className="editFormDiv">
                    <form className="editForm" onSubmit={handlePlaceSubmit}>
                      <label htmlFor="uid">Plot ID</label>
                      <input
                        type="number"
                        name="uid"
                        id="uid"
                        className="editInput"
                        onChange={handleChange}
                      />

                      <br />
                      <input
                        type="submit"
                        value="Place Plot"
                        className="maintenanceFormSubmit"
                      />
                    </form>
                  </div>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>

                <div>
                  <h1>Plots that are not in a room: </h1>
                  <ul>
                    {plotsNotInRoom.map((item) => (
                      <li key={item.plotid}>ID: {item.plotid} - Name: {item.plotno}</li>
                    ))}
                  </ul>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )}

      {/* {PlotShowModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button
                            className="close-btn"
                            onClick={() => setPlotShowModal(false)}
                        >
                            <FaTimes />
                        </button>
                        <div className="centered relativeCentered">
                            <h1 className="title">Info Plot: {selectedName}</h1>
                            <div className="centered relativeCentered">
                                Row: {selectedRow} Column: {selectedCol} Floor: {selectedFloor}
                            </div>
                            <Link key={selectedName} to={`/plot/${selectedUid}`} >
                                <div className="maintenanceFormSubmit">
                                    Go to {selectedName}
                                </div>
                            </Link>
                            <div className="editFormDiv">
                                <form className="editForm" onSubmit={handleDeleteSubmit}>
                                    <input
                                        type="submit"
                                        value="Delete"
                                        className="maintenanceFormSubmit"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
    </div>
  );
}

export default requireAuth(Room);


